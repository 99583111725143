/* You can add global styles to this file, and also import other style files */
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "https://fonts.googleapis.com/css2?family=Encode+Sans+Condensed:wght@100;300;400;700&display=swap";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
// @import './assets/css/bulma/bulma.sass';
// Import only what you need from Bulma
@import "../node_modules/bulma/sass/utilities/_all.sass";
@import "../node_modules/bulma/sass/base/_all.sass";
@import "../node_modules/bulma/sass/elements/button.sass";
@import "../node_modules/bulma/sass/elements/container.sass";
@import "../node_modules/bulma/sass/elements/title.sass";
@import "../node_modules/bulma/sass/form/_all.sass";
@import "../node_modules/bulma/sass/components/navbar.sass";
@import "../node_modules/bulma/sass/layout/hero.sass";
@import "../node_modules/bulma/sass/layout/section.sass";
@import "../node_modules/bulma/bulma.sass";

@font-face {
  font-family: 'Metropolis-Bold';
  src: url('./assets/fonts/Metropolis-Bold.eot');
  src: local('☺'), url('./assets/fonts/Metropolis-Bold.woff') format('woff'), url('./assets/fonts/Metropolis-Bold.woff') format('truetype'), url('./assets/fonts/Metropolis-Bold.svg') format('svg');
  font-weight: bold;
}

@font-face {
  font-family: 'Metropolis';
  src: url('./assets/fonts/Metropolis-Regular.eot') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./assets/fonts/Metropolis-Regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./assets/fonts/Metropolis-Regular.woff') format('woff'),
    /* Pretty Modern Browsers */
    url('./assets/fonts/Metropolis-Regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./assets/fonts/Metropolis-Regular.svg') format('svg');
  /* Legacy iOS */
}

// Set your brand colors
$arabama-green: #aad043;
$arabama-black: #000000;
$arabama-light-black: #4a4a4a;
$arabama-heading: #565f60;
$white: #ffffff;
$grey-3: #8a4d76;
$pink: #fa7c91;
$brown: #757763;
$beige-light: #d0d1cd;
$beige-lighter: #eff0eb;
$border: #F1F1F1;
$red: rgb(255, 0, 0);
$light-grey: #545D5E;
$black-1: #272323;
$black-2: #414141;
$half-white: #FAFAFA;
$half-white2: #959494;
$grey-1: #f4f4f4;
$grey-2: #F8F8F8;
$grey-3: #F5F5F5;
$pale-green: #F8FDEB;
// Update Bulma's global variables
$family-sans-serif: "Nunito", sans-serif;
$grey-dark: $brown;
$grey-light: $beige-light;
$primary: $arabama-green;
$success: $arabama-green;
$link: $pink;
$widescreen-enabled: false;
$fullhd-enabled: false;

// Update some of Bulma's component variables
$body-background-color: $beige-lighter;
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none !important;
}
a.navbar-item:focus-within {
  background-color: transparent;
  color: inherit;
}

*:focus {
  outline: none !important;
}

html {
  scroll-behavior: smooth;
}

.input {
  height: 45px;
}

.input:focus,
.textarea:focus,
.select select:focus,
.is-focused.input,
.is-focused.textarea,
.select select.is-focused,
.input:active,
.textarea:active,
.select select:active,
.is-active.input,
.is-active.textarea,
.select select.is-active {
  border-color: #DCDEDE !important;
  box-shadow: none !important;
}

.button.is-success {
  background-color: $arabama-green;
  border-color: #DCDEDE !important;
}

.button.is-success:hover {
  background-color: $arabama-green;
  color: white !important;
}

img {
  max-width: 100%;
}

.form-control {
  border-radius: 0 !important;
}

.navbar {
  @include mobile {
    background-color: #f6f6f6 !important;
  }
}

@for $j from 1 to 1000 {
  .is-height-#{$j} {
    height: 1px * $j !important;
  }
}

@for $k from 1 to 1000 {
  .text-size-#{$k} {
    font-size: 1px * $k !important;
  }
}

@for $l from 1 to 1000 {
  .is-width-#{$l} {
    width: 1px * $l !important;
  }
}

@for $l from 1 to 1000 {
  .is-border-#{$l} {
    border-radius: 1px * $l !important;
  }
}

@for $l from 1 to 1000 {
  .is-pa-t-#{$l} {
    padding-top: 1px * $l !important;
  }
}

@for $l from 1 to 1000 {
  .is-pa-l-#{$l} {
    padding-left: 1px * $l !important;
  }
}

@for $l from 1 to 1000 {
  .is-pa-r-#{$l} {
    padding-right: 1px * $l !important;
  }
}

@for $l from 1 to 1000 {
  .is-pa-b-#{$l} {
    padding-bottom: 1px * $l !important;
  }
}

@for $l from 1 to 1000 {
  .is-pa-#{$l} {
    padding: 1px * $l !important;
  }
}

@for $l from 1 to 1000 {
  .is-ma-l-#{$l} {
    margin-left: 1px * $l !important;
  }
}

@for $l from 1 to 1000 {
  .is-ma-r-#{$l} {
    margin-right: 1px * $l !important;
  }
}

@for $l from 1 to 1000 {
  .is-ma-b-#{$l} {
    margin-bottom: 1px * $l !important;
  }
}

@for $l from 1 to 1000 {
  .is-ma-t-#{$l} {
    margin-top: 1px * $l !important;
  }
}

@for $l from 1 to 1000 {
  .is-ma-#{$l} {
    margin: 1px * $l !important;
  }
}

$text-aligns: left top right bottom;

@each $a in $text-aligns {
  .text-align-#{$a} {
    text-align: #{$a};
  }
}

.cursor-default {
  cursor: pointer;
}

.text-dec-none {
  text-decoration: none;
}

html,
body {
  font-family: "Metropolis", "Verdana", Arial, sans-serif !important;
  background-color: #f4f6fa !important
}
  
h1,
h2,
h3,
h4,
h5 {
  @extend .heading-font;
}

.heading-color-1 {
  color: $black-2 !important;
}

.subheading-color-1 {
  color: $arabama-heading !important;
}

.subheading-color-2 {
  color: $arabama-heading !important;
}
.font-12{
  font-size: 12px !important;
}
.font-13{
  font-size: 13px !important;
}
.font-14{
  font-size: 14px !important;
}
.font-15{
  font-size: 15px !important;
}
.font-16{
  font-size: 16px !important;
}
.font-17{
  font-size: 17px !important;
}
.font-18{
  font-size: 18px !important;
}
.font-20{
  font-size: 20px !important;
}
.font-22{
  font-size: 22px !important;
}
.font-24{
  font-size: 24px !important;
}
@media screen and (min-width: 1500px) {
  .font-12{
    font-size: 14px;
  }
  .font-13{
    font-size: 15px;
  }
  .font-14{
    font-size: 16px;
  }
  .font-15{
    font-size: 17px;
  }
  .font-16{
    font-size: 18px;
  }
  .font-17{
    font-size: 19px;
  }
  .font-18{
    font-size: 20px;
  }
  .font-20{
    font-size: 22px;
  }
  .font-22{
    font-size: 24px;
  }
  .font-24{
    font-size: 26px;
  }
}

.box {
  box-shadow: 0 2px 3px 0 rgb(120 131 145 / 30%) !important;
  border-radius: 5px !important;
}

.card {
  box-shadow: none;

  &:hover {
    box-shadow: none !important;
  }
}

.encode-font {
  font-family: "Metropolis", "Verdana", Arial, sans-serif !important;
}

.heading-font {
  font-family: 'Metropolis-Bold';
}
.heading-b-color{
  color: $arabama-black;
}
.navbar {
  background-color: #eaeaea;

  &.is-fixed-top {
    padding: 20px 5px;
  }
}

@media screen and (min-width: 1024px) {
  .navbar {
    min-height: 48px !important;
  }
}

.how-it-works {
  padding-left: 24px;

  @include mobile {
    padding-left: 14px;
  }
}

.navbar-brand {
  align-items: center !important;

  @include mobile {
    justify-content: start;
  }

  >img {
    @include desktop {
      width: 140px;
    }

    @include tablet-only {
      width: 140px;
    }

    @include mobile {
      width: 110px;
    }
  }

  .close-ham {
    display: none;
  }

  &.is-active {
    .navbar-burger {
      .ham-active {
        display: none;
      }

      .close-ham {
        display: block;
        opacity: 1;
        animation: fade 1s linear;
        width: 30px;
        height: 20px;
      }
    }
  }
}

.line-height {
  line-height: 1;
}

.inner-class {
  .navbar {
    background-color: #eaeaea;
  }

  .inner_nav {
    background-color: #efefef;
    position: relative;
    padding: 8px 0;
    min-height: 72px;
   

    @include desktop {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    @include tablet-only {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    @include mobile {
      min-height: 50px;
      padding: 0;
    }
  }

  .navbar-brand {
    >img {
      @include desktop {
        width: 140px;
      }

      @include tablet-only {
        width: 140px;
      }

      @include mobile {
        width: 110px;
      }
    }
  }
}

@keyframes fade {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

a {
  &.navbar-item {
    color: $arabama-heading;

    &:hover {
      background-color: transparent;
      color: $arabama-green ;
    }
  }
}

.banner-wrapper {
  background: linear-gradient(180deg, #FCFCFC 0%, #F0F0F0 100%);
  padding-top: 20px;

  h2 {
    font-size: 0.823rem !important;
  }

  @include mobile {
    background: none;
    padding-top: 0px;
  }
}

.mobile-banner {
  background-color: #f6f6f6;

  .banner-img {
    img {
      width: 35%;
      transform: translateX(86%);
    }
  }
}

.desktop-card {
  padding: 55px 0;
  background-color: $white;
  .min-height {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include tablet {
      min-height: 36vh;
    }
  }
}

.home-banner {
  margin-left: 20px;
  display: flex;
  justify-content: center;
}

h1 {
  &.slide-text {
    color: $black-2;
    font-weight: 700;
    line-height: 1.3;
    font-size: 2rem;
  }
}

h2 {
  &.slide-text {
    color: $black-2;
    font-weight: 700;
    font-size: 1rem;
    margin-bottom: 3.5rem;

    @include mobile {
      margin-bottom: .2rem;
      padding: 8px !important;
    }
  }
}

h3 {
  &.section-title {
    font-weight: 800;
  }

  &.title-2 {
    @include mobile {
      font-size: 1.625rem;
    }

    @include desktop {
      font-size: 1.75rem;
    }
  }
}

.content {
  h4 {
    font-size: 1.15rem;
    color: $arabama-heading;

    &.article-title {
      font-size: 1.25rem;
      line-height: 1.2;

      @include mobile {
        font-size: 1rem;
      }
    }
  }
}

.sec-title-wrapper {
  display: flex;

  @include mobile {
    flex-direction: column;
  }
}

.content-inner {

  h4 {
    font-size: 1.75rem;
    color: $arabama-heading;
    margin: 24px 0 16px 0;

    @include mobile {
      font-size: 1.5rem;
    }
  }

  .border-sep {
    border-top: 1px solid $beige-light ;
  }
}

.article-details {
  font-size: 16px;

  @include mobile {
    font-size: 15px;
  }
}

.service-card {
  @include mobile {
    min-width: auto;
    max-width: initial;
  }

  p {
    font-size: 1rem;
    font-weight: 600;
  }
}

.has-text-success {
  color: $arabama-green !important;
}

.benefits {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-bottom: 32px !important;
  background-color: $white;
  position: relative;

  &::before {
    content: "";
    width: 30px;
    height: 30px;
    position: absolute;
    right: 30px;
    bottom: -30px;
    background: url(../src/assets/img/callout.png)no-repeat;
    background-size: contain;
  }

  &:last-child {
    &::before {
      display: none;
    }
  }

  .steps {
    width: 46px;
    background-color: $arabama-green;
    color: $white;
    font-size: 1.75rem;
    padding: 10px 10px 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  p {
    @extend .encode-font;
    line-height: 1.5;
    margin-bottom: 8px;
  }

  .points {
    font-size: 0.765rem;
  }

  .points-no {
    line-height: 1;
  }
}

.rounded-image {
  width: 80%;
  border-radius: 100%;
}

.text_30 {
  font-size: 1.125rem;
  @extend .encode-font;
  font-weight: 400;

  @include mobile {
    font-size: 1rem;
  }
}

.text_30m {
  @extend .text_30;
  font-weight: 500;
}

p {
  &.text_30 {
    @extend .text_30;
  }
}

.w-100 {
  width: 100% !important;
}

.services-wrapper {
  .service-title-wrapper {
    position: absolute;
    bottom: 0;
    background-color: $white;
    right: 0;

    .service-title {
      position: relative;
      padding: 14px 0;

      h6 {
        color: $black-1;
        padding-right: 20px;
        font-size: 2.125rem;
        margin-bottom: 0;
      }

      &::before {
        display: block;
        width: 0;
        height: 0;
        border-bottom: 88px solid $white;
        border-left: 60px solid transparent;
        content: '';
        position: absolute;
        left: -72px;
        bottom: -13px;
      }
    }
  }

  .services {
    position: relative;
    margin-right: 20px;
    background-color: $arabama-green;

    h5 {
      font-size: 2.625rem;
    }

    .details {
      @include desktop {
        margin-top: 130px;
      }

      @include mobile {
        margin-top: 20px;
      }
    }

    &:first-child {
      @include desktop {
        padding-right: 80px;
      }
    }

    &:nth-child(2) {
      @include desktop {
        padding-left: 80px;
      }
    }
  }
}

.service-details {
  @include desktop {
    padding-left: 80px;
  }

  .list-style {
    margin-left: 1rem;

    li {
      list-style-type: none;
      display: flex;
      margin-bottom: 12px;

      &::before {
        content: "";
        background: url(../src/assets/img/points.png) no-repeat;
        display: inline-block;
        height: 24px;
        width: 24px;
        background-size: contain;
        margin-right: 10px;
      }
    }
  }
}

.home-search {
  input {
    height: 60px;
  }

  ::placeholder {
    color: $half-white2;
    font-size: 16px !important;
  }

  :-ms-input-placeholder {
    color: $half-white2;
    font-size: 16px;
  }

  ::-ms-input-placeholder {
    color: $half-white2;
    padding-left: 10px;
    font-size: 16px;
  }
}

.cmd-tag {
  margin-right: 10px;
  @extend .encode-font;
  font-weight: 400;

  @include desktop {
    font-size: 1rem;
    padding: 5px 18px;
  }

  @include mobile {
    font-size: .825rem;
    padding: 5px 12px;
  }
}

.rating-block {
  .rating-headline {
    @extend .encode-font;
    font-weight: 400;

    @include desktop {
      font-size: 1.125rem;
    }
  }

  .rating-content {
    @extend .encode-font;
    font-weight: 400;

    @include desktop {
      font-size: 1.125rem;
    }

    @include mobile {
      font-size: 1rem;
    }
  }

  .garage-dtl {
    @extend .encode-font;
    font-weight: 400;
    color: $arabama-green;

    @include desktop {
      font-size: 1.125rem;

    }
  }

  .reservation {
    @extend .encode-font;
    font-weight: 400;

    @include desktop {
      font-size: 1.125rem;
    }
  }

  .place {
    @extend .reservation;

    @include desktop {
      font-size: 1.125rem;
    }

    @include mobile {
      font-size: 1rem;
    }
  }
}

.ps-relative {
  position: relative;
}

.ps-comment {
  position: absolute;

  @include mobile {
    position: static;
  }
}

.rounded {
  border-radius: 100%;
}

.block-padding {
  padding: 50px 0;
}

h5 {
  &.ques-heading {
    font-size: 1.125rem;
    font-weight: 700;
    color: #424348;
    margin: 2rem 0 1.5rem 0;
  }
}

.inner-class {
  background-color: #f4f6fa;
}

.stepper-nav {
  width: 100%;
}

.d-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-center-space-between {
  @extend .d-center;
  justify-content: space-between;
}

.w-bg-with-border-radius {
  background-color: white;
  border-radius: 10px;
  margin-top: 2.5rem;
}

.section-padding {
  @include mobile {
    padding: 0 22px;
  }
}

.sidebar-block {
  background-color: #FCFCFC;
  border: none;
  box-shadow: 0 2px 3px 0 rgb(120 131 145 / 30%);
  margin-bottom: 1rem;
}

.back_img {
  margin-right: 1.5rem;
  width: 40px;
  height: 40px;
}

.button {
  font-size: 16px;
  &:focus{
    border: none;
  }
}

.button-cta {
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 5px;
  font-size: 16px;
  font-family: "Metropolis-Bold";
  color: $white;
  width: 100%;
  border: none;
  cursor: pointer;
  display: block;
  background-color: $arabama-green;
}

.button-secondary {
  padding: 10px 0px;
  font-size: 16px;
  font-family: "Metropolis-Bold";
  color: #494949;
  width: 100%;
  border: none;
  cursor: pointer;
  display: block;
  background-color: #F5F5F5;
  border-radius: 5px;
}

.star-rating {
  display: flex;
  align-items: center;

  .rating-count {
    margin-left: 8px;
    display: block;
  }
}

.features-wrapper {
  display: flex;
  .features-inner {
    display: flex;
    align-items: center;
    margin-right: 8px;

    @include mobile {
      margin-bottom: 8px;
    }
  }

}

.garage-facility {
  height: 14px;

  &.state-control {
    height: 20px;
  }
}

.align-center {
  display: flex;
  align-items: center;
}

.brand-color {
  color: $arabama-green;
}
.mat-dialog-container {
  color: #4a4a4a !important;
}
.mat-expansion-panel {
  .mat-expansion-panel-header {
    &:hover {
      background: transparent !important;
    }

    padding-top: 8px;
    padding-bottom: 8px;
  }

  &.mat-expanded {
    .mat-expansion-panel-header {
      background-color: $white;
    }

  }
}

.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.text-dec-up {
  text-transform: uppercase;
}

.calendar-wrapper {
  .tag-bg {
    padding-top: 6px;
    padding-bottom: 6px;
    width: 93% !important;
    height: 90%;
    border-radius: 7px !important;
    font-size: 1rem !important;

    &.is-success {
      background-color: $arabama-green;
      color: white;
    }

  }

  .has-background-success {
    span {
      color: white !important;
    }
  }
  .mat-expansion-panel-body{
    padding: 0;
  }
}

.login-card {
  background-color: white !important;
  border-radius: 10px;
  box-shadow: 0 .5rem 1rem rgba(51, 51, 51, .15) !important;
  margin: 50px auto;
  width: 32%;

  @media only screen and (min-width: 768px) {
    width: 60%;
    max-width: 600px;
  }

  input,
  button {
    height: 50px;
  }

  @include desktop {
    padding: 20px 30px;
  }

  @include tablet-only {
    padding: 20px;
  }

  @include mobile {
    padding: 20px;
    width: 90%;
  }
}

.mat-expansion-panel-body {
  @include mobile {
    padding: 0 15px 10px !important;
  }
}
.estimate-quote-mobile{
  .mat-expansion-panel-body {
    @include mobile {
      padding: 0 14px 0px !important;
    }
  }
}

.progressbar {
  li {
    @include mobile {
      width: 19% !important;
    }
  }
}


.but-nav-left {
  position: relative;
  left: -15px;
}

.but-nav-right {
  position: relative;
  right: -15px;
}

.mar-top-mobile {
  @include mobile {
    margin-top: 20px !important;
  }
}

.no-data {
  color: $primary;
  justify-content: center;
  display: flex;
  padding-top: 10px;
  font-weight: bold;
  font-size: 17px;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: $primary;
  stroke-width: 5%;
}

.spinner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mat-simple-snackbar-action{
  .mat-button-wrapper {
    color: white;
  }
}


.error-snackbar {
  background: $red;
  color: white;
  font-weight: bold;
}

.success-snackbar {
  background: $primary;
  color: black;
  font-weight: bold;
}

ul {
  font-size: 14px;
}

.service-content {
  .popular-service {
    @include tablet-only {}

    .popular-service-list {
      flex-direction: column;

      .service-card {
        background: $white;
        border: 1px solid #EDEDED;
        border-left: 10px solid $arabama-green;
        margin-bottom: 15px;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        min-height: 45px;
        padding-top: 8px;
        padding-bottom: 8px;

        @include mobile {
          min-height: auto;
          padding-top: 8px;
          padding-bottom: 8px;
          border-left: 8px solid $arabama-green;
        }

        >div {
          display: flex;
          align-items: center;
        }

        img {
          margin-right: 40px;
          margin-left: 20px;
          width: 32px;
          height: 32px;
          background-size: contain;

          @include mobile {
            width: 32px;
            height: 32x;
            background-size: contain;
          }
        }
      }
    }

    h4 {
      font-size: 1.875rem;
      line-height: 30px;
    }
  }

}



.caption {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: $black-2;
}

.footer {
  background-color: $light-grey;

  .content {
    width: 85%;
    margin: 0 auto;
    @media only screen and (max-width: 1024px) {
      width: 97%;
    }
  }
}

.social-icon-wrapper {
  display: flex;
  justify-content: center;

  @media only screen and (min-width: 1025px) {
    justify-content: flex-end;
  }

  .social-icon {
    background-color: $arabama-green;
    height: 40px;
    width: 40px;
    border: none;

    i {
      color: $white;
      font-size: 1.2rem;
    }
  }
}
.service-option{
  .mat-tab-body-content{
    overflow: hidden !important;
}
}
.all-service {
  font-size: 1.234rem;

  @include mobile {
    margin: 0 10px;
  }
}
.mat-autocomplete-panel{
  position: absolute !important;
}
.banner-text-animation {
  display: flex;
  flex-wrap: wrap;
}

.like-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .like-cta {
    background-color: $arabama-green;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      color: $white;
    }
  }
}

.ham-menu-icon {
  @media only screen and (min-width: 1025px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

h1 {

  @media only screen and (max-width: 1024px) {
    font-size: 1.7rem;
  }

  @media only screen and (min-width: 1025px) {
    font-size: 2.3rem;
  }
}

h3 {
  font-size: 2.4rem;

  @media only screen and (max-width: 1024px) {
    font-size: 1.125rem;
  }

  @media only screen and (min-width: 1025px) {
    font-size: 2.4rem;
  }
}

.container-wrapper {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;

  @include mobile {
    padding-right: 0.523rem;
    padding-left: 0.523rem;
  }
  .relative {
    position: relative;

    .profile-image {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      outline: 6px solid #d8d6d6;
    }

    .change-profile {
      background-color: #d6d6d6;
      outline: 3px solid #aad043;
      border-radius: 50%;
      position: absolute;
      margin-left: 120px;
      bottom: 10px;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

}

@media (min-width: 1200px) {
  .container-wrapper {
    max-width: 1050px;
  }
}

@media (max-width: 600px) {
  .container-wrapper {
    max-width: 1024px;
  }
}

.answer-wrapper {
  @media only screen and (min-width: 1025px) {
    margin-right: 50px;
  }
}

.services-aside {
  border-radius: 10px;

  .header {
    background-color: #efefef;
    padding: 0px 14px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px 5px 0px 0px;

    img {
      width: 25px;
      margin-right: 10px;
    }
  }

  .content {
    padding: 7px 20px 7px 20px;
    border-radius: 0 0 5px 5px;

    ul {
      margin: 0;
    }
  }
  .ser-content{
    padding: 9px 20px 1px 20px;
    border-radius: 0 0 5px 5px;

    ul {
      margin: 0;
    }
  }
}

.service-child {
  padding: 8px 0px;

  .header {
    color: #9E9E9E;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 1024px) {
  .navbar-burger {
    display: flex;
    align-items: center;

    &:hover {
      background: transparent;
    }
  }

  .stepper-nav {
    padding: .025rem 0;
  }

  .inner-stepper {
    padding-top: 100px;

    .navbar {
      position: fixed;
      width: 100%;
      top: 0;

      .navbar-end {
        position: absolute;
        top: 4px;
        right: 0;

        a {
          margin-right: 0 !important;
        }
      }
    }
  }
}
.navbar{
  .navbar-end{
    // display: flex;
    // justify-content: flex-end;
  }
}
.inner-stepper {
  padding-top: 70px;
  padding-bottom: 20px;
  @media screen and (max-width:1024px) {
    padding-top: 125px;
  }
  @media screen and (max-width:768px) {
    padding-top: 95px;
  }

  .navbar {
    position: fixed;
    width: 100%;
    top: 0;
  }
}

.header-fixed {
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 999;
}

.location-cta-wrapper {
  .location-cta {
    display: flex;
    align-items: center !important;
    justify-content: center;
    border: none;
    font-size: 12px;
    background-color: $white;
    padding-right: 0;
    margin-right: 8px;
    position: absolute;
    top: 24px;
    right: 0;

    img {
      width: 20px;
      margin-right: 8px;
    }
  }
}

.signin-title {
  font-size: 1.25rem;
  @media only screen and (max-width: 500px) {
    font-size: 1rem;
 }
}

.facebook_login {
  padding: 16px 1.5rem;
  display: flex;
  background-color: #4267b2;
  color: $white;
  border-radius: 5px;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: 1215px) and (max-width: 1588px) {
    padding: 16px .8rem;
  }

  .icon {
    background-color: $white;
    margin-right: 8px;
  }

  &:hover {
    background-color: #3b5c9f;
    color: $white;
  }

  .title {
    color: $white;
    font-size: 0.98rem;

    @media only screen and (min-width: 1215px) and (max-width: 1588px) {
      font-size: 0.79rem;
    }
  }
}

.d-flex {
  display: flex;
}

.google_login {
  display: flex;
  background-color: #4285f4;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  border: 1px solid #4285f4;

  .icon {
    height: 40px;
    width: 40px;
    background: $white;
    padding: 10px;
    border-radius: 50%;
  }

  .title {
    padding: 18px 1.5rem;
    color: $white;
    font-size: 0.98rem;

    @media only screen and (min-width: 1215px) and (max-width: 1588px) {
      padding: 16px .8rem;
      font-size: 0.88rem;
    }
  }
}

.exp-tag {
  background-color: $arabama-green;
  color: $white;
  padding: 6px 16px;
  display: inline-block
}

.icon-size {
  width: 34px;
  height: 34px;
  display: inline-block;
  background-size: contain !important;
  content: '';
}

.estimates {
  position: relative;
  box-shadow: 2px 2px 4px 2px #d9dce9;
  background-color: white;
  margin: 20px 0 20px 0;
  border-radius: 14px;

  .estimates-no {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }


  .cancel {
    box-shadow: 0 2px 4px 0 #d9dce9;
    position: absolute;
    right: 20px;
    background: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 12px;
    border: none;
    cursor: pointer;

    img {
      height: 20px;
      transform: rotate(90deg);
      opacity: 0.5;
    }

    @include mobile {
      right: 3px;
      top: 3px;
    }
  }
  .upcoming-app-details,
  .garage-logo {
    display: none;
  }

}


.appointment {
  @extend .estimates;
  @extend .p-5;

  .date,
  .cancel,
  .estimate-details {
    display: none;
  }

  .upcoming-app-details,
  .garage-logo {
    display: block;
  }

}

.appoint-wrapper {
  background-color: #F5F5F5;
  padding: 24px 0px;
  width: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 768px) {
      width: 200px;
  }
  .up-timing {
    display: flex;
    align-items: center;

    &::before {
      @extend .icon-size;
      background: url(./assets/svg/clock.svg)no-repeat;
      margin-right: 18px;
    }
  }

  .up-appointment {
    display: flex;
    align-items: center;
    margin: 0 auto;

    &::before {
      @extend .icon-size;
      background: url(./assets/svg/date-icon.svg)no-repeat;
      margin-right: 18px;
    }
  }
}

.appExpCan {
  @extend .estimates;
  @extend .p-1;

  .garage-logo
   {
    display: block;
  }
  .upcoming-app-details {
    display: flex;
    @media only screen and (max-width: 450px) {
      display: block;
      .is-6-mobile{
        width: 100% !important;
      }
    }
  }

  .vehicle-details {
    display: none;
  }
}

.estExpCan {
  @extend .estimates;
  @extend .p-1;

  .garage-logo,
  .upcoming-app-details {
    display: block;
  }

  .vehicle-details {
    display: none;
  }

}

.no-envents {
  background-color: $white;
  border-radius: 10px;
  box-shadow: 2px 2px 4px 2px #d9dce9;
  min-height: 300px;
  padding: 10px;
  margin-bottom: 10px;
}

.appoiment-img {
  img {
    width: 130px;
  }
  @media only screen and (max-width: 768px) {
    img{
      width: 70px;
    }
  }
}

.line-pricing {
  border-bottom: 2px dotted #cdd1d6;
  list-style-type: none;
  margin: 0;
  padding: 1rem 0;
  position: relative;

  .line-item-label {
    background: $white;
    color: #616a76;
    font-size: 0.856rem;
    position: absolute;
    padding-right: 1rem;
    bottom: -0.5rem;
  }

  .line-item-pricing {
    background: $white;
    color: #404040;
    font-size: 0.856rem;
    font-weight: 500;
    position: absolute;
    padding-left: 1rem;
    right: 0;
    bottom: -0.5rem;
  }
}

.mat-tab-group.mat-primary .mat-ink-bar {
  background-color: $arabama-green !important;
}
.mat-tab-group{
  font-family: 'Metropolis', "Verdana", Arial, sans-serif ;
}
.service-option {
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;

  .detail-line-items {
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 10px;
    margin-right: 10px !important;
  }
}

.brand-logo {
  background-color: #f4f6fa;
  border-radius: 10px;

  img {
    width: 90px;
  }
}

.flex-end {
  display: flex;
  justify-content: flex-end;

  @media only screen and (max-width: 1024px) {
    justify-content: flex-start;
  }
}

.padding-m {
  @media only screen and (max-width: 1024px) {
    padding: 0 12px !important;
  }
}

[data-tip] {
  position: relative;
}

[data-tip]:before {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #d70000;
  position: absolute;
  top: 45px;
  left: 35px;
  z-index: 8;
  font-size: 0;
  line-height: 0;
  width: 0;
  height: 0;
  opacity: 0.6;
}

[data-tip]:after {
  position: absolute;
  top: 50px;
  left: 0px;
  padding: 5px 8px;
  background: #d70000;
  opacity: 0.6;
  color: $white;
  z-index: 9;
  font-size: 0.8rem;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 5px;
  white-space: nowrap;
  word-wrap: normal;
}

[data-tip]:hover:before,
[data-tip]:hover:after {
  display: block;
}

.current-location {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-12px);
  cursor: pointer;
  background-color: $white;

  img {
    width: 1.125rem;
  }

  @include mobile {
    right: 9px;
    padding: 1px;
    top: 50%;

    img {
      width: 1.125rem;
    }
  }
}
.veh-filter{
  @media only screen and (min-width: 400px) and (max-width: 500px) {
    .mat-form-field-infix{
      width: 210px !important;
    }
  }
  @media only screen and (max-width: 400px) {
    .mat-form-field-infix{
      width: 150px !important;
    }
}

}
.year-filter{
  @media only screen and (max-width: 500px) {
    .mat-form-field-infix{
      width: 70px !important;
    }
}
}
.nav-wrapper {
  position: sticky;
  top: 0;
  z-index: 999;
  width: 100%;
}

.notification {
  border-radius: 10px;
  background-color: #f4f6fa;
  margin-top: -25px;
}

.border-bottonm {
  border-bottom: 1px solid #dbdbdb;
}

.min-height-tab {
  min-height: 400px;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: rgb(228, 228, 228);
  -webkit-box-shadow: inset 0 0 6px rgba(112, 112, 112, 0.3);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #9c9c9c;
}

.close-wrapper {
  display: flex;
  justify-content: flex-end;
  .closed {
    color: red;
    border: 1px solid #cdcdcd;
    padding: 4px 14px;
    border-radius: 4px;
    text-transform: capitalize;
  }
}

.mat-tab-label-active {
  font-weight: bold;
}

.mat-tab-label-content {
  font-size: 1rem;
}

.location-icon {
  width: 32px;
  margin-left: 10px;
}

.inner-header {
  position: fixed;
  width: 100%;
  top: 0;
}

.inner-mobile-menu {
  position: absolute;
  right: 5px;
  top: 10px;

  .dropdown-menu {
    left: -161px;
  }
}

.padding-20 {
  padding: 20px;

  @include mobile {
    padding: 6px;
  }
}

.vehicle-list {
  .brand-logo {
    background-color: transparent;
  }
}

.inner-class .location-img {
  top: 32px !important;
}

.location-icon-inner {
  width: 22px;
  margin-top: 30px;

  @include mobile {
    margin-top: 22px;
  }
}

.location-icon-home-mobile {
  position: absolute;
  top: 6px !important;
  width: 1.15em !important;
  left: 14px !important;
}

.announcement {
  text-align: right;

  @include mobile {
    text-align: center;
    padding: 6px;
  }
}


.hoverService {
  display: flex;

  .deleteService {
    display: none !important;
  }

  &:hover .deleteService,
  &:active .deleteService {
    display: flex !important;
    cursor: pointer;
  }

  .delete-wrapper {
    display: flex;
    justify-content: center;

    @include mobile {
      justify-content: center;
    }
  }
}
.back_button{
  border-radius: 100%;
  box-shadow: 0 2px 2px 0;
  color: #dbdde1;
  background-color: #aad043;
  height: 40px;
  width: 40px;
}

.nav-before {
  content: '';
  width: 24px;
  height: 24px;
  background-size: contain !important;
  margin-right: 10px;
}

.navbar-menu {
  .navbar-end {
    .navbar-item {
      a {
        &.navbar-item {
          padding: 8px 14px;
        }
      }
      .nav-msg {
        &:before {
          @extend .nav-before;
          background: url(./assets/svg/msg.svg)no-repeat;
        }
      }

      .nav-park {
        &:before {
          @extend .nav-before;
          background: url(./assets/svg/my-car-park.svg)no-repeat;
        }
      }

      .nav-setting {
        &:before {
          @extend .nav-before;
          background: url(./assets/svg/my-account.svg)no-repeat;
        }
      }

      .nav-profile {
        &:before {
          @extend .nav-before;
          background: url(./assets/svg/my-account.svg)no-repeat;
        }
      }
    }

    .navbar-item {
      &.is-hoverable {
        .navbar-dropdown {
          .ser-history {
            &::before {
              @extend .nav-before;
              margin-right: -5px;
              width: 40px;
              background: url(./assets/svg/service-his.svg)no-repeat;
            }
          }

          .reviews {

            &::before {
              @extend .nav-before;
              margin-right: -5px;
              width: 40px;
              background: url(./assets/svg/reviews.svg)no-repeat;
            }
          }


          .help-menu {
            &::before {
              @extend .nav-before;
              background: url(./assets/svg/help.svg)no-repeat;
            }
          }

          .exit {
            &::before {
              @extend .nav-before;
              background: url(./assets/svg/exit.svg)no-repeat;
            }
          }

          .dash-menu {
            &::before {
              @extend .nav-before;
              background: url(./assets/svg/dashboard.svg)no-repeat;
              width: 22px;
              height: 22px;
            }
          }
          .home-page{
            &::before{
              @extend .nav-before;
              background: url(./assets/svg/dashboard.svg)no-repeat;
              width: 22px;
              height: 22px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .inner-class {
    .ham-inner-img {
      display: none;
    }
  }
}

.show-map-wrapper {
  @include mobile {
    flex-direction: column;
  }

  .buttons {
    @include mobile {
      margin-top: 10px;
    }
  }
}

.name {
  @include mobile {
    margin: 14px 0 10px 0;
  }
}

.catagory-title {
  border-bottom: 4px solid #D9D9D9;

  &.active {
    border-bottom: 4px solid $arabama-green;
  }

}

.itemized_estimate {
  padding: 4px 0 4px 0;

  table {
    tr {
      td {
        padding: 10px 10px !important;
      }
    }
  }

  input[type="checkbox"] {
    width: 26px;
    height: 26px;
    accent-color: $arabama-green;
  }

  .mat-expansion-panel-body {
    padding: 0;
  }

  .mat-expansion-panel-header {
    padding-left: 0;
  }

  .mat-expansion-panel-header-title {
    margin-right: 70px;
  }

  .mat-expansion-panel-content {
    margin-right: 97px;
  }

  .min-width-50 {
    min-width: 50%;
  }

  .estimation-total {
    margin-right: 44px;
  }


  @media only screen and (max-width: 769px) {
    .mat-expansion-panel{
      overflow: visible;
    }
    .mobile-panel-head {
      margin-right: 26px;
    }

    .mat-expansion-panel-content {
      margin-right: 13px !important;
    }

    .text-size-20 {
      font-size: 0.956rem !important;
    }
    .mat-expansion-panel-header-title {
      margin-right: 0px;
    }
    .mat-expansion-panel-header {
      padding-right: 0;
    }
    input[type="checkbox"] {
      width: 20px;
      height: 20px;
      accent-color: #aad043;
    }
  }
  @media only screen and (max-width: 550px) {
    .mat-expansion-panel-content {
      margin-right: 7px !important;
    }
  }
  @media only screen and (max-width: 400px) {
    .mat-expansion-panel-content {
      margin-right: 4px !important;
    }
  }
}

.pre-desc-wrapper {
  position: relative;
  left: 19px;
  padding: 0 7px;
}

.eco-desc-wrapper {
  position: relative;
  left: -4px;
  padding: 0 6px;
}
.diagonal-strikethrough {
  text-decoration: line-through;
  color: red;
}
.catagory-price {
  background: $grey-3;
  padding: 16px 10px;

  &.active {
    background-color: $pale-green;
  }
}

.catagory-price-wrapper {
  background-color: $grey-3;
  padding: 0px 0;

  table {
    background-color: $grey-3;
    padding: 0 20px;
  }

  &.active {
    background-color: $pale-green;

    table {
      background-color: $pale-green;
    }
  }
}

.width-95 {
  width: 95%;
}

.eco-price-wrapper {
  background-color: $grey-3;
  padding: 16px;

  table {
    background-color: $grey-3;
    padding: 0 20px;
  }
}

.description-wrapper {
  table {
    padding: 0 20px;

    td {
      border: none !important;
      text-align: right;
    }
  }
}

.color-arabama-green {
  color: $arabama-green;
}


.service-history {
  overflow-x: hidden;

  .mat-expansion-panel {
    &.mat-expanded {
      margin-bottom: 20px;

      .mat-expansion-panel-header {
        border-bottom: 0px;
        margin-bottom: 0;
        border-radius: 5px 5px 0px 0px;
      }
      .mat-expansion-panel-content{
        border-radius: 0px 0px 5px 5px;
      }
    }

    .mat-expansion-panel-header-title {
      @include mobile {
        margin-right: 0;
      }
    }

    .mat-expansion-panel-header {
      border: 1px solid #CBCBCB;
      margin-bottom: 20px;
      padding-top: 0;
      padding-bottom: 0;

      &:hover {
        background: none !important;
      }
    }

    .mat-expansion-indicator {
      margin-left: 14px;
    }

    .mat-expansion-panel-content {
      border: 1px solid #CBCBCB;
      border-top: 0px;
    }
  }
  .feedback {
    display: flex;
    align-items: center;

    &:before {
      @extend .icon-size;
      width: 40px;
      background: url(./assets/svg/feedback.svg)no-repeat;
    }
  }

  .history-price {
    background-color: #F6F6F6;
  }

  .mat-form-field-appearance-fill {
    .mat-form-field-flex {
      background-color: transparent;
      padding-top: 0;
    }

    .mat-form-field-infix {
      padding-top: 0;
    }
  }
}
.service-history-accor{
  mat-expansion-panel-header{
    height: max-content !important;
  }
  @media screen and (max-width: 500px) {
    .mat-expansion-panel-header{
      padding-left: 10px;
      padding-right: 10px;
    }
    .mat-expansion-panel-body{
      padding-left: 5px !important;
      padding-right: 5px !important;
      padding-bottom: 0px !important;
    }
    .mat-expansion-indicator{
      margin-left: 0px !important;
    }
  }
}

.mat-tab-label {
  background-color: #f2f2f2;
  margin: 0 3px;

  &.mat-tab-label-active {
    background-color: $white;
  }
}
.faq{
  .mat-expansion-indicator::after{
    border-width: 0 3px 3px 0;
    padding: 4px;
    }
}
.mat-expansion-panel {
  .mat-expansion-panel-header {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
.mat-expansion-panel-header, .mat-expansion-panel-content{
  font-family: 'Metropolis', "Verdana", Arial, sans-serif ;
}

#dropDownProvince {
  .search-results {
    @include mobile {
      overflow-x: auto;
      top: -55px;
    }
  }

  button {
    &.list-group-item {
      &.list-group-item-action {
        width: 100%;
        text-align: inherit;
        position: relative;
        display: block;
        padding: 0.75rem 1.25rem !important;
        background-color: #fff;
        border: 1px solid #e5e8ed;
        font-size: inherit !important;
        line-height: inherit !important;
        font-family: inherit;
        font-weight: 400;

        &:hover {
          background-color: #aad043;
          color: #fff;
        }
      }
    }
  }
}

input.mat-input-element {
  margin-top: 0;
}

.new-location-page {
  .global-search-menu {
    top: -24px;
    width: 100%;

    @include mobile {
      top: 24px;
    }

    .search-results {
      height: 200px;
      overflow-x: auto;
    }
  }
}
.service-history-accor{
  .mat-expansion-panel-body{
    padding-bottom: 0;
    padding-left: 10px;
  }
}

.dob-field{
  .mat-form-field{
    width: 100%;
  }
  .mat-form-field-flex{
    padding: 0 !important;
    background-color: white !important;
    border: 1px solid #dbdbdb !important;
    border-radius: 4px !important;
    cursor: pointer !important;
    &:hover{
    border-color: #DCDEDE !important;
    box-shadow: none !important;
    }
  }
  .mat-form-field-infix{
    padding: 0 !important;
  }
  .mat-button-wrapper{
    color: #8e8e8e !important;
  }
  .mat-input-element{
    font-size: 1.1rem !important;
    padding-left: 15px;
    cursor: pointer  !important;
    color: #363636 !important;
    margin-bottom: 5px;
  }
  .mat-form-field-underline{
    display: none;
  }
  .mat-form-field-wrapper{
    padding-bottom: 0 !important;
    border-color: red !important;
  }
}

.mat-calendar{
  height: 100% !important;
}
.mat-calendar-body-selected{
  background-color: $primary !important;
}
.mat-focus-indicator{
  font-size: 16px !important;
}
.mat-calendar-table-header{
  tr{
  th{
    font-size: 16px !important;
    text-align: center !important;
  }
}
}
.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
  border-color: $primary !important;
  background-color: #ebf5e0  !important;
}
.mat-calendar-body-in-range::before{
  background-color: #ebf5e0 !important;
}
.mat-calendar-body-selected{
  box-shadow: none !important;
  &:hover{
    background-color: $primary !important;
  }
}
.mat-calendar-body-cell{
  &:hover{
    .mat-calendar-body-cell-content{
      background-color: $primary !important;
    }
  }
}
.mat-icon-button{
  width: 45px !important;
  height: 45px !important;
}
.mat-calendar-body-active{
  .mat-focus-indicator{
    background-color: $primary !important;
  }
}
@media screen and (max-width: 768px) {
  .dob-field{
  .mat-input-element{
    font-size: 1rem !important;
  }
}
}